import styled from '@emotion/styled'

export const StyledContainer = styled.section`
  background-color: var(--color-black-3);
  width: 100%;
  height: 150px;
  padding: 0 20px;
  overflow: hidden;
  position: relative;

  & > div {
    display: flex; 
    justify-content: left;
    align-items: center;
    height: 150px;
    
    div {
      height: 150px;
    }
  
    h2 {
      color: var(--color-light);
      font-size: 30px;
      font-weight: 100;
      text-transform: uppercase;
  
      i, span {
        color: var(--color-primary);
      }
  
      i {
        margin-right: 20px;
      }

      .subTittle {
        font-size: 15px;
        display: block;
        color: var(--color-light);
        margin-top: 10px;
      }
    }
  }

  img {
    position: absolute;
    top: calc(50% - 100px);
    opacity: .1;
    right: 0;
    height: 200px;
  }

  @media (min-width: 920px) {
    height: 300px;
    padding: 0 100px;

    div {
      height: 300px;

      h2 {
        font-size: 50px;

        .subTittle {
          font-size: 20px;
        }
      }
    }

    img {
      top: calc(50% - 225px);
      height: 450px;
    }
  }
`
