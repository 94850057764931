import React from 'react'
import PropTypes from 'prop-types'
import { Field, CustomLoading } from './styles'

const SelectText = (props) => {
  const {
    label,
    value,
    name,
    onChange,
    options,
    isRequired,
    notEmptyOption,
    isDisabled,
    className,
    isLoading,
  } = props

  const renderOptions = (item, index) => (
    <option key={index} value={item.value}>{item.label}</option>
  )

  return (
    <Field className={className}>
      <span>
        {label}
        {isRequired && (
          <i className="fas fa-asterisk" title="campo obrigatorio"/>
        )}
      </span>
      <select
        name={name}
        value={value}
        onChange={onChange}
        required={isRequired}
        disabled={isDisabled || isLoading}
      >
        {!notEmptyOption && (<option>Selecione uma opção</option>)}
        {options.map((item, index) => renderOptions(item, index))}
      </select>
      {isLoading && (
        <CustomLoading className="fas fa-spinner" />
      )}
    </Field>
  )
}

SelectText.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isRequired: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.any).isRequired,
  notEmptyOption: PropTypes.bool,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
}

SelectText.defaultProps = {
  isRequired: false,
  notEmptyOption: false,
  isDisabled: false,
  className: '',
  isLoading: false,
}

export default SelectText
