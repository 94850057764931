import styled from '@emotion/styled'

export const StyledBGModal = styled.div`
  background-color: var(--color-hover-black-dark);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: center;
`

export const StyledModalContent = styled.div`
  background-color: var(--color-light);
  max-height: 90%;
  padding: 30px 20px 20px;
  max-width: 90%;
  top: 100px;
  overflow-x: auto;

  & > div {
    h2 {
      color: var(--color-primary);
      margin-bottom: 20px;

      &.alert {
        color: var(--color-alert);
      }

      &.success {
        color: var(--color-success);
      }

      &.warning {
        color: var(--color-warning);
      }
    }

    p {
      &.title {
        font-weight: 600;
        font-size: 18px;
        margin-bottom: 20px;
      }
    }
  }

  &.sm {
    width: 350px;
  }

  &.md {
    width: 650px;
  }

  &.lg {
    width: 1280px;
  }

  .close {
    float: right;
    margin: -30px -30px 0 0;
    height: 30px;
    line-height: 30px;
    padding: 0;
    width: 80px;
    border-radius: 0 0 0 20px;

    &:hover {
      box-shadow: 100px 100px inset var(--color-hover-black-light);
    }

    &.alert {
      background-color: var(--color-alert);
    }

    &.success {
      background-color: var(--color-success);
    }

    &.warning {
      background-color: var(--color-warning);
    }
  }

  &.alert {
    background-color: #fde3e3;
  }

  &.success {
    background-color: #d2ffd4;
  }

  &.warning {
    background-color: #fdf8bf;
  }
`