export const armedEscortList = [
  {
    benefits: 'Visibilidade Nacional',
    contractor: 'Canal Único Nacional para Acionamento',
    armedEscort: 'Exposição da Empresa no Mercado',
  }, {
    benefits: 'Pré analise documental',
    contractor: 'Segurança na Contratação de Fornecedor',
    armedEscort: 'Do Contratante',
  }, {
    benefits: 'Validação de Certificações',
    contractor: 'Fornecedor devidamente homologado',
    armedEscort: 'Cliente consolidado no mercado',
  }, {
    benefits: 'Software de Gestão',
    contractor: 'Para Acionar a Rede Nacional',
    armedEscort: 'Para ser acionado pelos Contratantes',
  }, {
    benefits: 'Controle Operacional de Missão',
    contractor: 'Acompanhamento de status da Missão',
    armedEscort: 'Adequação ao uso de equipe e VTR',
  }, {
    benefits: 'Controle Financeiro',
    contractor: 'Fechamento missão a missão com exatidão',
    armedEscort: 'Fechamento missão a missão com exatidão',
  }, {
    benefits: 'Relatório de Missões',
    contractor: 'Contratadas (Total e por Prestador)',
    armedEscort: 'Prestadas (Total e por Contratante)',
  }, {
    benefits: 'Segurança Juridica',
    contractor: 'Controle e Frequencia dos Agentes',
    armedEscort: 'Controle e Frequencia dos Agentes',
  }, {
    benefits: 'Consultoria',
    contractor: 'Trabalhista, Tributária e Organizacional',
    armedEscort: 'Trabalhista, Tributária e Organizacional'
  }, {
    benefits: 'Suporte',
    contractor: 'Técnico Operacional e Jurídico',
    armedEscort: 'Técnico Operacional e Jurídico'
  }
]

