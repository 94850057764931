export const clientList = [
  {
    product: 'Distribuidores',
    icon: 'fas fa-truck-moving',
    id: 0,
    list: [
      {
        imgUrl: 'jsl.png',
        title: 'JSL - Julio Simões',
        colorType: null,
      }, {
        imgUrl: 'jbs.png',
        title: 'Grupo JBS',
        colorType: null,
      }, {
        imgUrl: 'ibl.png',
        title: 'IBL Logística',
        colorType: null,
      }, {
        imgUrl: 'gruposc.png',
        title: 'Grupo Santa Cruz',
        colorType: null,
      }, {
        imgUrl: 'cbf.png',
        title: 'Confederação Brasileira de Futebol',
        colorType: null,
      },
    ],
  },
  {
    product: 'Corretoras e Consultorias',
    icon: 'fas fa-shield',
    id: 3,
    list: [
      {
        imgUrl: 'globalseg.png',
        title: 'Global Seg Corretora de Seguros',
        colorType: null,
      }, {
        imgUrl: 'solobrasil.png',
        title: 'Grupo Solo Brasil',
        colorType: null,
      }, {
        imgUrl: 'foxamerica.png',
        title: 'Fox América',
        colorType: null,
      }, {
        imgUrl: 'gera.png',
        title: 'Gera Seguros',
        colorType: null,
      }, {
        imgUrl: 'ntm.png',
        title: 'NTM Solutions',
        colorType: null,
      }, {
        imgUrl: 'ff.png',
        title: 'FF Gerenciamento de Risco',
        colorType: null,
      },
    ],
  },
  {
    product: 'Gerenciadoras',
    icon: 'fas fa-globe',
    id: 2,
    list: [
      {
        imgUrl: 'shogun.png',
        title: 'Shogun Gestão de Risco',
        colorType: null,
      }, {
        imgUrl: 'jec.png',
        title: 'J&C Gestão de Risco',
        colorType: null,
      }, {
        imgUrl: 'monytor.png',
        title: 'Monytor',
        colorType: null,
      }, {
        imgUrl: 'opentech.png',
        title: 'Opentech',
        colorType: null,
      }, {
        imgUrl: 'global5.png',
        title: 'Global 5',
        colorType: null,
      }, {
        imgUrl: 'atlas.png',
        title: 'Atlas',
        colorType: null,
      }, {
        imgUrl: 'base.png',
        title: 'Base',
        colorType: null,
      }, {
        imgUrl: 'mundialrisk.png',
        title: 'Mundial Risk',
        colorType: 'white',
      }, {
        imgUrl: 'ibl.png',
        title: 'Instituto Brasil Logística',
        colorType: null,
      }, {
        imgUrl: 'noxx.png',
        title: 'Nox',
        colorType: null,
      }, {
        imgUrl: 'raster.png',
        title: 'Raster',
        colorType: null,
      }, {
        imgUrl: 'rister.png',
        title: 'Rister Serviços',
        colorType: null,
      },
    ],
  }, {
    product: 'Seguradoras',
    icon: 'fas fa-file-signature',
    id: 1,
    list: [
      {
        imgUrl: 'ARGOLOG.png',
        title: 'Argo Seguros',
        colorType: null,
      }, {
        imgUrl: 'axa.png',
        title: 'Axa',
        colorType: null,
      }, {
        imgUrl: 'sura.png',
        title: 'Sura',
        colorType: null,
      }, {
        imgUrl: 'sompo.png',
        title: 'Sompo',
        colorType: null,
      }, {
        imgUrl: 'itauseguros.png',
        title: 'Itaú Seguros',
        colorType: null,
      }, {
        imgUrl: 'azulseguros.png',
        title: 'Azul Seguros',
        colorType: null,
      }, {
        imgUrl: 'hdi-sguros.png',
        title: 'HDI Seguros',
        colorType: null,
      }, {
        imgUrl: 'chubb.png',
        title: 'Chubb',
        colorType: null,
      },
    ],
  },
  {
    product: 'Transportador',
    icon: 'fas fa-truck-loading',
    id: 4,
    list: [
      {
        imgUrl: 'accord.png',
        title: 'Accord Transportes',
        colorType: null,
      }, {
        imgUrl: 'aerotrafic.png',
        title: 'Aerotrafic Transportes e Logísticas',
        colorType: 'white',
      }, {
        imgUrl: 'ajtransportes.png',
        title: 'AJ Transportes',
        colorType: null,
      }, {
        imgUrl: 'alfacargas.png',
        title: 'Alfa Cargas',
        colorType: 'white',
      }, {
        imgUrl: 'atualcarga.png',
        title: 'Atual Carga',
        colorType: null,
      }, {
        imgUrl: 'bahiacargo.png',
        title: 'Bahia Cargo Transporte',
        colorType: 'white',
      }, {
        imgUrl: 'bomfim.png',
        title: 'Bomfim',
        colorType: null,
      }, {
        imgUrl: 'boxer.png',
        title: 'Boxer',
        colorType: null,
      }, {
        imgUrl: 'brix.png',
        title: 'Brix Cargo',
        colorType: null,
      }, {
        imgUrl: 'cbl.png',
        title: 'CBL',
        colorType: null,
      }, {
        imgUrl: 'conexaomaritima.png',
        title: 'Conexão Marítima Soluções e Logística',
        colorType: null,
      }, {
        imgUrl: 'contrail.png',
        title: 'Contrail',
        colorType: null,
      }, {
        imgUrl: 'dng.png',
        title: 'DNG Transportes',
        colorType: null,
      }, {
        imgUrl: 'efatha.png',
        title: 'Efatha Soluções Tecnológicas',
        colorType: null,
      }, {
        imgUrl: 'mirassol.png',
        title: 'Expresso Mirassol',
        colorType: null,
      }, {
        imgUrl: 'taubate.png',
        title: 'Expresso Taubaté',
        colorType: null,
      }, {
        imgUrl: 'farta.png',
        title: 'Farta Transportes',
        colorType: null,
      }, {
        imgUrl: 'fitlog.png',
        title: 'Fitlog',
        colorType: null,
      }, {
        imgUrl: 'GATLOG.png',
        title: 'GAT Logística',
        colorType: 'white',
      }, {
        imgUrl: 'gefrio.png',
        title: 'Gefrio Logística Multimodal',
        colorType: 'white',
      }, {
        imgUrl: 'graneleiro.png',
        title: 'Graneleiro Transporte Rodoviário',
        colorType: null,
      }, {
        imgUrl: 'gxlog.png',
        title: 'GXLOG Transporte e Logística',
        colorType: null,
      }, {
        imgUrl: 'intercargas.png',
        title: 'Intercargas Gestão Logísta e Transportes',
        colorType: null,
      }, {
        imgUrl: 'isas.png',
        title: 'ISA`S TRansportes',
        colorType: null,
      }, {
        imgUrl: 'levcarga.png',
        title: 'Levcarga Transportes',
        colorType: null,
      }, {
        imgUrl: 'lmfrotas.png',
        title: 'LM Frotas',
        colorType: null,
      }, {
        imgUrl: 'luck.png',
        title: 'Luck Express',
        colorType: null,
      }, {
        imgUrl: 'marajo.png',
        title: 'Transportes Marajós',
        colorType: null,
      }, {
        imgUrl: 'mmcm.png',
        title: 'MMCM Logística',
        colorType: null,
      }, {
        imgUrl: 'mmx.png',
        title: 'MMX Transportes',
        colorType: 'white',
      }, {
        imgUrl: 'moraes.png',
        title: 'Moraes Serviços Logísticos',
        colorType: null,
      }, {
        imgUrl: 'multicargo.png',
        title: 'Multicargo Transporte e Logística',
        colorType: null,
      }, {
        imgUrl: 'partumlog.png',
        title: 'Partum Log',
        colorType: null,
      }, {
        imgUrl: 'polar.png',
        title: 'Polar Truck Service',
        colorType: null,
      }, {
        imgUrl: 'reiter.png',
        title: 'Reiter Log',
        colorType: null,
      }, {
        imgUrl: 'rodluki.png',
        title: 'RodoLuki',
        colorType: null,
      }, {
        imgUrl: 'safralogistica.png',
        title: 'Safralogística',
        colorType: null,
      }, {
        imgUrl: 'santini.png',
        title: 'Santini Transportes',
        colorType: null,
      }, {
        imgUrl: 'somerlog.png',
        title: 'Somerlog Logística e Transportes',
        colorType: 'white',
      }, {
        imgUrl: 'total.png',
        title: 'Total Express',
        colorType: null,
      }, {
        imgUrl: 'transcap.png',
        title: 'Transcap',
        colorType: null,
      }, {
        imgUrl: 'fabris.png',
        title: 'Trans Fabris',
        colorType: null,
      }, {
        imgUrl: 'walmor.png',
        title: 'Walmor Transportes',
        colorType: 'white',
      },
    ],
  },
]
