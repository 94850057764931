import styled from '@emotion/styled'

export const Field = styled.label`
  width: 100%;
  display: block;
  margin-bottom: 20px;
  position: relative;

  & > span {
    color: var(--color-black-3);
    font-weight: 700;
    text-transform: uppercase;
    font-size: 13px;

    i {
      font-size: 8px;
      vertical-align: top;
      color: red;
      margin-left: 3px;
    }
  }

  input, select {
    margin-top: 8px;
    border-radius: 5px;
    height: 45px;
    padding: 0 10px;
    font-size: 16px;
    border: 1px solid;
    background-color: transparent;
  }

  textarea {
    margin-top: 8px;
    border-radius: 5px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid;
    margin-bottom: 0;
  }
`

export const CustomLoading = styled.i`
  @keyframes Loading {
    from { transform: rotate(0deg) }
    to { transform: rotate(360deg) }
  }

  position: absolute;
  top: 32px;
  z-index: 10;
  font-size: 30px;
  left: calc(50% - 15px);
  animation: Loading linear 1.5s infinite;
`