import React from 'react'
import PropTypes from 'prop-types'
import { Field, CustomLoading } from './styles'

const TextareaText = (props) => {
  const {
    label,
    value,
    name,
    onChange,
    type,
    onBlur,
    isRequired,
    className,
    isLoading,
    isDisabled,
  } = props

  return (
    <Field className={className}>
      <span>
        {Boolean(label) && label}
        {isRequired && (
          <i className="fas fa-asterisk" title="campo obrigatorio"/>
        )}
      </span>
      <textarea
        name={name}
        value={value}
        onChange={onChange}
        type={type}
        onBlur={onBlur}
        required={isRequired}
        disabled={isDisabled || isLoading}
      />
      {isLoading && (
        <CustomLoading className="fas fa-spinner" />
      )}
    </Field>
  )
}

TextareaText.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  type: PropTypes.string,
  isRequired: PropTypes.bool,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
}

TextareaText.defaultProps = {
  type: 'text',
  onBlur: null,
  isRequired: false,
  className: '',
  label: '',
  isLoading: false,
  isDisabled: false,
}

export default TextareaText
