import styled from '@emotion/styled'

export const StyledContainer = styled.section`
  p {
    margin-bottom: 10px;
    line-height: 18px;
  }

  h3 {
    margin: 25px 0 10px;
    border-bottom: 2px solid var(--color-primary);
    padding-bottom: 5px;

    i {
      color: var(--color-primary);
      margin-right: 10px;
    }
  }

  h4 {
    margin: 0 0 25px;
    font-size: 20px;
    text-align: center;
    display: block;
  }

  ul {
    li {
      margin-bottom: 10px;
    }
  }

  @media (min-width: 760px) {
    p {
      font-size: 18px;
      line-height: 21px;
    }

    h3 {
      margin: 25px 0 20px;
      font-size: 22px;
    }

    ul {
      li {
        font-size: 18px;
      }
    }
  }
`

export const StyledArticle = styled.article`
  max-width: 1280px;
  margin: 50px auto 0;
  padding: 10px;

  @media (min-width: 760px) {
    padding: 10px 25px;
  }
`