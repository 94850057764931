import styled from '@emotion/styled'

export const StyledContainer = styled.section`
  border: 1px solid var(--color-black-4);
  padding: 40px 15px 5px;
  border-radius: 5px;
  position: relative;
  margin-bottom: 80px;

  h2 {
    position: absolute;
    display: inline-block;
    color: var(--color-primary);
    background-color: var(--color-black-4);
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 20px;
    font-weight: 400;
    top: -20px;
  }

  @media (min-width: 760px) {
    padding: 50px 20px 10px;

    h2 {
      font-size: 22px;
      padding: 15px 20px;
      top: -25px;
    }
  }
`
