import styled from '@emotion/styled'

export const SectionContainer = styled.section`
  max-width: 980px;
  margin: 0 auto;
`

export const ContactContainer = styled.section`
  padding: 30px 20px 100px;

  p {
    margin-bottom: 10px;
    line-height: 18px;
  }

  h2 {
    padding-bottom: 10px;
    margin-bottom: 20px;
    border-bottom: 3px solid var(--color-primary);

    i {
      color: var(--color-primary);
      margin-right:  7px;
    }
  }

  .left {
    width: 100%;
    margin-bottom: 50px;

    .contact-side {
      margin-bottom: 30px;

      & > div {
        font-weight: 700;
        font-size: 18px;
        margin: 20px 0;

        a {
          width: 30px;
          height: 30px;
          background-color: var(--color-black-3);
          border: 1px solid var(--color-primary);
          border-radius: 5px;
          display: inline-block;
          line-height: 29px;
          text-align: center;
          margin-right: 7px;
          font-size: 16px;

          &:hover {
            background-color: var(--color-primary);
            border-color: var(--color-black-3);
            color: var(--color-black-3);
          }
        }

        & > span {
          display: inline-block;
          vertical-align: middle;

          &:first-of-type {
            width: 75px;
          }

          &:last-of-type {
            width: calc(100% - 75px);
          }

          span {
            display: block;
          }
        }

        &.email {
          p {
            display: flex;
            gap: 5px;
            align-items: center;

            i {
              font-size: 18px;
              width: 30px;
              height: 30px;
              background-color: var(--color-black-3);
              border: 1px solid var(--color-primary);
              border-radius: 5px;
              color: var(--color-primary);
              padding: 5px;
            }

            a {
              width: 100%;
              background-color: transparent;
              border: none;
              color: var(--color-black);
              text-align: left;
              height: auto;
              font-size: 18px;
              line-height: initial;
              margin: 0;

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }

    @media (min-width: 760px) {
      .contact-side {
        width: 300px;
        display: inline-block;
        vertical-align: top;
      }
    }

    @media (min-width: 1280px) {
      .contact-side {
        width: 400px;
      }
    }
  }

  .right {
    iframe {
      width: 100%;
      height: 400px;
    }
  }

  @media (min-width: 1280px) {
    // .left {
    //   width: calc(100% - 450px);
    //   margin-right: 50px;
    //   display: inline-block;
    //   vertical-align: top;
    // }

    p {
     font-size: 18px;
      line-height: 23px;
    }
  
    .right {
      width: 400px;
      display: inline-block;
    }
  }
`

export const FormContact = styled.form`
  @media (min-width: 760px) {
    width: calc(100% - 320px);
    display: inline-block;
    margin-left: 20px;
  }

  @media (min-width: 1280px) {
    width: calc(100% - 420px);
  }

  button {
    color: var(--color-black-3);
    font-weight: 700;
    margin: 0;

    &:hover {
      color: var(--color-primary);
      background-color: var(--color-black-3);
    }
  }
`
