export const serviceList = [
  {
    icon: 'fas fa-road',
    title: 'Acompanhamento Logístico',
    text: 'Recursos de controle e acompanhamento que agregam grande valor ao negócio através da logística, possibilitando a visualização da frota e produzindo indicadores para medição de resultados e otimizando os custos e recursos nas operações.',
    url: '/servicos#logistic'
  }, {
    icon: 'fas fa-satellite',
    title: 'Rastreamento',
    text: 'Localizador 100% satelital • Integração com o cliente • Embarque de itinerário • Data centers STI • Envio de SMS e e-mail • Telemetria',
    url: '/servicos#tracking'
  }, {
    icon: ['fas fa-volume-off spinoff', 'fas fa-car-side'],
    isPolice: true,
    title: 'Escolta Armada Caracterizada',
    text: 'Benefícios da “Rede Nacional de Escolta Armada” • Empresas devidamente homologadas e regularizadas nos órgãos pertinentes em todo território nacional',
    url: '/servicos#armed-escort'
  }, {
    icon: 'fas fa-user-shield',
    title: 'Gestão de Escolta Armada',
    text: 'O serviço de escolta armada é uma ferramenta preventiva e reativa empregada para a proteção de bens e conferência de situações divergêntes do programado que podem ser até suspeitas ou certezas de sinistros.',
    url: '/servicos#armed-escort-menagement'
  }, {
    icon: 'fas fa-user-ninja',
    title: 'Pronta Resposta, Auditor ou Fiscal de Rota',
    text: 'Entende-se que o agente de campo PRONTA RESPOSTA, é o representante de ordem e preposto da Spartacus Brazil e também do cliente, neste contexto ele deve observar com segurança, inteligência e maestria, todo o envolto ao sinistro ou evento',
    url: '/servicos#route-inspector'
  }, {
    icon: 'fas fa-user-secret',
    title: 'Inteligência, Sindicância ou Regulação',
    text: 'Produção de conhecimentos para planejar e executar ações para inibir perdas, criar instrumento para a instauração de procedimentos legais por parte das autoridades competentes e ou compliance',
    url: '/servicos#inteligence-regulation'
  }, {
    icon: 'fas fa-balance-scale',
    title: 'Apoio Jurídico Rota',
    text: 'Prestamos apoio jurídico à empresas que não têm departamento próprio ou que não querem empenhar sua estrutura orgânica nos processos',
    url: '/servicos#juridical-support'
  },
]