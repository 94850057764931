import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import Logo from '@/imgs/logo-horizontal-white.png'
import Menu from './Menu'
import { HeaderContent, StyledButtonMobile } from './styles'

const Header = (props) => {
  const { isMobile, transparencyMenu } = props
  const [openedMenu, setOpenedMenu] = useState(false)

  const onMenuClick = () => {
    setOpenedMenu(false)
  }

  return (
    <HeaderContent className={transparencyMenu ? 'scrolled' : ''}>
      <Link to='/'>
        <img src={Logo} className="logo" alt='Logo Spartacus Brazil' />
      </Link>
      {isMobile ? (
        <>
          <StyledButtonMobile
            className={openedMenu ? 'active' : ''}
            onClick={() => setOpenedMenu(!openedMenu)}
          >
            <i className="fas fa-bars" />
          </StyledButtonMobile>
          {openedMenu && (<Menu
            handleClick={onMenuClick}
            isOpened
          />)}
        </>
      ) : (
        <Menu isScrolled={transparencyMenu} />
      )}
    </HeaderContent>
  )
}

Header.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  transparencyMenu: PropTypes.bool.isRequired,
}

export default Header
