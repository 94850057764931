import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { MenuContainer } from './styles'

const showClientArea = false

const Menu = (props) => {
  const { isOpened, isScrolled, handleClick } = props
  return (
    <MenuContainer className={`${isOpened ? 'mobile' : ''} ${isScrolled ? 'scrolled' : ''}`}>
      <ul className={showClientArea ? '' : 'no-client'}>
        <li>
          <NavLink
            to="/"
            activeclassname="active"
            onClick={handleClick}
          >
            {!isScrolled && (<i className="fas fa-home" />)}
            <span>HOME</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/quem-somos"
            activeclassname="active"
            onClick={handleClick}
          >
            {!isScrolled && (<i className="far fa-building" />)}
            <span>QUEM SOMOS</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/servicos"
            activeclassname="active"
            onClick={handleClick}
          >
            {!isScrolled && (<i className="fas fa-shield" />)}
            <span>SERVIÇOS</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/contato"
            activeclassname="active"
            onClick={handleClick}
          >
            {!isScrolled && (<i className="fas fa-at" />)}
            <span>CONTATO</span>
          </NavLink>
        </li>
      </ul>
      {showClientArea && (
        <button className='button' onClick={handleClick}>
          {!isScrolled && (<i className="fas fa-user-lock" />)}
          <span>ACESSO CLIENTE</span>
        </button>
      )}
    </MenuContainer>
  )
}

Menu.propTypes = {
  isOpened: PropTypes.bool,
  isScrolled: PropTypes.bool,
  handleClick: PropTypes.func,
}

Menu.defaultProps = {
  isOpened: false,
  isScrolled: false,
  handleClick: null,
}

export default Menu
