import React from 'react'
import PropTypes from 'prop-types'
import { StyledContainer } from './styles'

const CardArticle = (props) => {
  const { title, children } = props

  return (
    <StyledContainer>
      <h2>{title}</h2>
      <div>
        {children}
      </div>
    </StyledContainer>
  )
}

CardArticle.propTypes = {
  children: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
}

export default CardArticle
