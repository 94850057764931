import React from 'react'
import HeaderArticle from '../fragments/HeaderArticle'
import {
  ContactContainer,
  SectionContainer,
} from './styles'

const Politics = () => (
  <div className='has-header'>
    <HeaderArticle>
      <i className="fa-solid fa-gavel" />
      Política <span>de</span> Privacidade
    </HeaderArticle>

    <ContactContainer>
      <SectionContainer>
        <div>
          <p>Seja bem-vindo a utilização da Plataforma Spartacus Brazil (“Spartacus”) disponibilizada pela Empresa Parmenes (“Parmenes”) e o aplicativo para smartphones Spartacus Brazil (“APP”) são utilizados para otimizar e contribuir para que os serviços de intermediação disponibilizados pela Parmenes sejam prestados com eficiência.</p>
          <p>Por favor, leia o texto abaixo referente a Política de Privacidade para obter as informações de como coletamos, protegemos e utilizamos seus dados quando você interage com o APP e a plataforma, através do APP ou através do ambiente WEB, mensagens eletrônicas.</p>
          <p>Ao acessar o aplicativo Prestador você indica que leu, aceita e concorda na sua integralidade com a Política de Privacidade, bem como declara saber que a mesma será alterada de tempos em tempos, sem necessidade de aviso prévio, e com o nosso armazenamento e processamento de tal informação. Caso não concorde, por favor, não faça uso do Aplicativo.</p>
          <p>Esta Política de Privacidade foi atualizada pela última vez em 13 de janeiro de 2023.</p>

          <h2>1. O que a Plataforma Spartacus Brazil coleta<span> Informações Pessoais que coletamos</span></h2>

          <p>1.1 A Plataforma Spartacus Brazil pode coletar diferentes tipos de informações que os usuários (“Usuários”) forneçam ao utilizar o Aplicativo, ao ser parceiro/funcionário de nossos clientes  ou fornecedores ou através da plataforma WEB e ao entrar em contato conosco por qualquer meio, ou informações decorrentes do uso do Aplicativo, incluindo, mas não se limitando a: nome, e-mail, informações de contato, informações sobre documentos  dos Usuários e dados de geolocalização.</p>
          <p>1.2 Serão consideradas informações pessoais quaisquer dados não-públicos capazes de identificar uma pessoa física ou jurídica e/ou fatos relacionados à vida privada de seu titular (“Informações Pessoais”).</p>
          <p>1.3 É possível que a Plataforma Spartacus obtenha informações, de fontes públicas ou de outros terceiros, que podem incluir detalhes de empregadores do Usuários e detalhes de atividades trabalhistas, bem como viagens, vínculos empregatícios e deslocamentos com veículos.</p>
          <p>1.4 Adicionalmente, os Usuários podem utilizar a Plataforma Spartacus para enviar mensagens para do Aplicativo ou vice-versa, agendar viagens e consultar detalhes de outras viagens realizadas por seus colegas ou funcionários, através dos serviços de rastreamento e do aplicativo.</p>

          <h2>2. Outras informações que coletamos</h2>

          <p>2.1 Podemos ainda coletar informações sobre como você interage com a Plataforma Spartacus, tais como:</p>
          <p>2.2 Sua utilização da Plataforma Spartacus (por exemplo, quais páginas você visitou e tempo de duração do acesso).</p>
          <p>2.3 Endereço IP e informações sobre o dispositivo que você utiliza para acessar a Plataforma Spartacus, tais como seu endereço de Media Access Control (MAC) e outros identificadores do hardware como Universally Unique Identifier (UUID), International Mobile Station Equipment Identity (IMEI), International mobile Subscriber Identity (IMSI) e Integrated Circuit Card Identifier (ICCID), tipo de sistema operacional (Windows, Android, iOS), resolução de tela, nome e versão do sistema operacional, fabricante e modelo do dispositivo, idioma, tipo e versão do navegador de Internet e o nome e versão da Plataforma Spartacus (APP ou Portal Web) que você está usando.2.3 Endereço IP e informações sobre o dispositivo que você utiliza para acessar a Plataforma Spartacus, tais como seu endereço de Media Access Control (MAC) e outros identificadores do hardware como Universally Unique Identifier (UUID), International Mobile Station Equipment Identity (IMEI), International mobile Subscriber Identity (IMSI) e Integrated Circuit Card Identifier (ICCID), tipo de sistema operacional (Windows, Android, iOS), resolução de tela, nome e versão do sistema operacional, fabricante e modelo do dispositivo, idioma, tipo e versão do navegador de Internet e o nome e versão da Plataforma Spartacus (APP ou Portal Web) que você está usando.</p>
          <p>2.4 Nós podemos combinar informações recebidas através da Plataforma Spartacus com informações que você tenha nos fornecido diretamente por outros meios ou ainda, informações obtidas através de outras fontes, para nos auxiliar no fornecimento de serviços de qualidade e customizar nossos serviços. Quando combinarmos informações não pessoais identificáveis com Informações Pessoais, nós vamos tratar a informação combinada como Informação Pessoal.</p>
          <p>2.5 Nós também coletamos ou compilamos informações de usuários (tais como números de inscrição ou informações de grupo demográfico) em uma base agregada e anônima. Podemos usar ou compartilhar tais informações com terceiros para qualquer finalidade lícita.</p>

          <h2>3. Uso de Cookies</h2>

          <p>3.1 Nós, incluindo nossos prestadores de serviços terceirizados, utilizamos determinadas ferramentas para coletar informações sobre você durante sua navegação na Plataforma Spartacus. Essas ferramentas utilizam cookies, que são pequenos arquivos de texto que são armazenados no disco rígido do seu dispositivo  (computador ou em qualquer outro dispositivo conectado à internet, tais como smartphones ou tablets). Os cookies são usados, entre outras coisas, para melhorar o desempenho da Plataforma Digital, para lhe fornecer opções de visualização personalizadas, para compilar relatórios estatísticos sobre as atividades da Plataforma Spartacus ou para fins de segurança. Também utilizamos cookies para reconhecê-lo como um visitante anterior da nossa Plataforma Spartacus ou para preencher formulários. Tais informações podem ser divulgadas a terceiros para o fornecimento de quaisquer das atividades acima mencionadas em nosso nome.</p>
          <p>3.2 Você pode configurar seu navegador para rejeitar cookies ou para notificá-lo quando um cookie for utilizado. No entanto, lembre-se, no entanto, que sem cookies você pode não ser capaz de tirar o máximo proveito de todos os recursos da nossa Plataforma Spartacus. Para saber mais sobre cookies, incluindo instruções específicas do seu navegador sobre como restringir ou bloquear cookies, acesse http://www.allaboutcookies.org.</p>

          <h2>4. Como usamos as Informações Pessoais</h2>

          <p>4.1 Utilizamos tais informações principalmente para abastecer a Plataforma Spartacus e suas ferramentas relacionadas para seu uso e para:</p>

          <ul>
            <li>Atender suas solicitações e responder às suas perguntas;</li>
            <li>Coordenar com Usuários (Transportadores, Embarcadores Motoristas, agentes de vigilância e outros participantes do setor de Transportes) o agendamento de missões de escolta e/ou viagens e troca de informações referentes aos veículos destes participantes;</li>
            <li>Oferecer serviços de transportes e gerenciamento de risco a você;</li>
            <li>Rastrear e fornecer atualização no status e localização dos Usuários;</li>
            <li>Gerar os históricos das viagens, posicionamentos ;</li>
            <li>Facilitar o uso de nossos serviços e Plataforma Spartacus para eliminar a necessidade de reinserir informações;</li>
            <li>Customizar a Plataforma Spartacus de acordo com os seus interesses e preferências;</li>
            <li>Enviar informações administrativas para você;</li>
            <li>Analisar e monitorar o uso da Plataforma Spartacus e fazer melhorias;</li>
            <li>Ajudar a proteger a Plataforma Spartacus, prevenir contra fraudes, atividades criminosas e outras responsabilidades legais;</li>
            <li>Entrar em contato com você sobre outros serviços e produtos da Spartacus ou de nossos parceiros;</li>
            <li>Executar outras atividades com o seu consentimento;</li>
            <li>Conduzir análise e auditoria de dados, desenvolver novos produtos, identificando tendências de uso, determinar a eficácia de nossas campanhas promocionais e operar e expandir nossas atividades de negócios;</li>
            <li>Cumprir a lei e nossas políticas e cooperar com as autoridades ou com partes litigantes em um processo legal ou administrativo, ou de outra forma permitida pela lei; e/ou</li>
            <li>Identificação e proteção contra ameaças à segurança, destruição de propriedade, incluindo ameaças ao nosso sistema de TI e outros ativos.</li>
          </ul>

          <p>4.2 A Spartacus também poderá usar e armazenar informações não pessoais e não identificáveis, incluindo informações anonimizadas e dados agregados, para vários fins, incluindo, sem limitação, para gerar relatórios e para melhorar os nossos serviços e a Spartacus pode compartilhar estes dados com terceiros.</p>

          <h2>5. Rastreamento por aparelho móvel e Consentimento para o Uso de Dados de Localização</h2>

          <p>5.1 A Spartacus disponibiliza o serviço de acompanhamento de missão de escolta por meio de forma que os Usuários vão fornecer sua localização para informar seu deslocamento.</p>
          <p>5.2 Os Usuários concordam em fornecer sua localização para o rastreamento de posições, bem como com a utilização dos dados de localização de acordo com os termos desta Política de Privacidade.</p>
          <p>5.3 As informações de localização serão obtidas através dos serviços de localização dos smartphones dos Usuários e são coletadas mesmo com o serviço em segundo plano ou quando não está em uso.</p>
          <p>5.4 Estas informações são necessárias para que nossa central de monitoramento e acompanhamento possam visualizar por onde você esta e fornecer apoio necessário em toda a missão de escolta.</p>

          <h2>6. Como compartilhamos as Informações Pessoais</h2>
          <p>6.1 Compartilharemos as Informações Pessoais coletadas por meio da Plataforma Spartacus apenas conforme descrito nesta Política de Privacidade, a menos que você concorde com formas adicionais de compartilhamento. Especificamente, podemos compartilhar as Informações Pessoais de Usuários:</p>

          <ul>
            <li>Com Motoristas, Transportadores, Embarcadores, Seguradoras, Gerenciadoras de Risco e outros participantes da cadeia de transporte, bem como com terceiros contratados ou autorizados a fornecer algum serviço relativos aos serviços destes e para os fins de coordenação, execução e monitoramento das cargas, e outros serviços legítimos para os fins relacionados a essa Política de Privacidade;</li>
            <li>Com empresas e fornecedores que nos ajudam a operar nossos negócios, fornecendo serviços como hospedagem do website, análise de dados, tecnologia da informação e prestação de infraestruturas conexas, atendimento ao cliente, envio de e-mail, realização de auditorias e outros serviços semelhantes, com a ressalva de que tais terceiros receberão as Informações Pessoais somente da Spartacus.</li>
            <li>Para cumprir com a lei ou outras obrigações legais, como responder a intimações, incluindo leis e outras obrigações legais fora do seu país de residência;</li>
            <li>Para responder aos pedidos de autoridades públicas e governamentais nacionais ou estrangeiras;</li>
            <li>Para proteger nossos direitos, operações ou bens, ou os de nossos usuários;</li>
            <li>Para investigar, prevenir ou tomar medidas contra potenciais ou suspeitas atividades ilegais, fraude, ameaças à segurança pessoal de qualquer pessoa, ou violações dos termos e condições da Plataforma Spartacus; e</li>
            <li>Com um(a) comprador(a) da Spartacus (ou de suas ações), em uma possível fusão ou aquisição. </li>
          </ul>

          <h2>7. Como você pode acessar, editar ou solicitar a exclusão de suas Informações Pessoais</h2>
          <p>7.1 Algumas partes de nossa Plataforma Spartacus podem permitir que você revise e edite as Informações Pessoais que você inseriu. Caso contrário, você pode entrar em contato conosco para solicitar acesso ou para que atualizamos ou corrijamos as Informações Pessoais coletadas por nossa Plataforma Spartacus.</p>
          <p>7.2 Exceto pelas Informações Pessoais que a Spartacus é obrigada por lei a manter, você pode solicitar que nós excluamos suas Informações Pessoais coletadas por meio do envio de um e-mail para o endereço de e-mail ao final desta Política de Privacidade.</p>

          <h2>8. Como protegemos suas Informações Pessoais</h2>
          <p>8.1 A Spartacus utiliza medidas técnicas e organizacionais, para ajudar a proteger as suas Informações Pessoais coletadas por meio da Plataforma Spartacus. Possuímos proteção física, eletrônica e procedimentais para proteger Informações Pessoais.</p>
          <p>8.2 As informações fornecidas por você, bem como a qualidade e atualização das mesmas, são de sua exclusiva responsabilidade e a Spartacus não tem qualquer responsabilidade caso você falhe em manter a segurança e confidencialidade destas informações.</p>

          <h2>9. Serviços ligados por link</h2>
          <p>9.1 Nós não somos responsáveis pela privacidade, informações ou outras práticas de quaisquer terceiros, incluindo quaisquer terceiros operando qualquer site ou serviço que estejam citados, por link, na Plataforma Spartacus, tais como redes sociais e serviços de geolocalização. A inclusão de um link na Plataforma Spartacus não implica o endosso do respectivo site ou serviço pela Spartacus e você deve ler e aceitar a Política de Privacidade do link antes de continuar o acesso a qualquer site ou utilizar quaisquer serviços desse site.</p>
          <p>9.2 Favor notar que não somos responsáveis pelas políticas e práticas de coleta, uso e divulgação (incluindo as práticas de segurança de dados) de outras organizações, incluindo qualquer Informação Pessoal que você divulga a outras organizações através de ou em conexão com nossa Plataforma Digital.</p>

          <h2>10. Mensagens Promocionais</h2>
          <p>10.1 A Spartacus poderá enviar e-mail e notificações a você por meio da Plataforma Spartacus sobre serviços relacionados ou similares aos que estão sendo fornecidos pela Spartacus e que possam ser de seu interesse. </p>

          <h2>11. Mudanças a esta Política de Privacidade</h2>
          <p> 11.1 Nós podemos mudar esta Política de Privacidade a qualquer momento, publicando uma versão atualizada.</p>

          <h2>12. Período de retenção</h2>
          <p>12.1 Nós retemos suas Informações Pessoais durante o período que julgarmos necessário para cumprir os propósitos descritos nesta Política de Privacidade, a menos que um período de retenção mais longo seja requerido ou permitido por lei.</p>

          <h2>13. Uso da Plataforma Digital por menores</h2>
          <p>13.1 Usuários com menos de 18 (dezoito) anos completos não devem utilizar esta Plataforma.</p>

          <h2>14. Informações sensíveis</h2>
          <p>14.1 Pedimos que não nos envie e não divulgue Informação Pessoal sensível (por exemplo, informação relacionada à origem racial ou étnica, opiniões políticas, religião ou outras crenças) na ou através da Plataforma Spartacus.</p>

          <h2>15 Como entrar em contato com a Spartacus a respeito de suas preocupações e perguntas sobre privacidade </h2>
          <p>15.1 Se você tiver perguntas ou comentários sobre nossas práticas de privacidade, favor enviar um e-mail para comercial@spartacusbrazil.com.br ou ligar para (11) 3938-0984.</p>

        </div>
      </SectionContainer>
    </ContactContainer>
  </div>
  )

export default Politics
