import React from 'react'
import PropTypes from 'prop-types'
import { Link } from "react-scroll"
import Truck from '@/imgs/trucks.jpg'
import Agent from '@/imgs/agent.jpg'
import JuridalSupport from '@/imgs/juridicalSupport.jpg'
import { armedEscortList } from '@/TextsPagesMock/armedEscort'
import HeaderArticle from '@/components/fragments/HeaderArticle'
import Footer from '@/components/Footer'
import {
  StyledContainer,
  StyledBreadcrumb,
  StyledLogistic,
  SectionContainer,
  ArmedEscort,
  ArmedEscortManagement,
  RouteInspector,
  InteligenceRegulation,
  JuridicalSupport,
  StyledTracking,
  StyledImageDemo,
} from './styles'

const Services = (props) => {
  const { isMobile, transparencyMenu } = props

  const renderArmedEscortList = (row, index) => (
    <div className='tr' key={index}>
      <div>
        {isMobile && (<span>Benefícios:</span>)}
        {row.benefits}
      </div>
      <div>
        {isMobile && (<span>Contratante:</span>)}
        {row.contractor}
      </div>
      <div>
        {isMobile && (<span>Escolta Armada:</span>)}
        {row.armedEscort}
      </div>
    </div>
  )

  return (
    <StyledContainer className='has-header'>
      <HeaderArticle>
        <i className="fas fa-shield" />
        Serviços <span>Ativos</span>
      </HeaderArticle>
  
      <StyledBreadcrumb>
        <li>
          <Link to="logistic">
            <i className="fas fa-angle-right" />
            ACOMPANHAMENTO LOGÍSTICO
          </Link>
        </li>
        <li>
          <Link to="tracking">
            <i className="fas fa-angle-right" />
            RASTREAMENTO
          </Link>
        </li>
        <li>
          <Link to="armed-escort">
            <i className="fas fa-angle-right" />
            ESCOLTA ARMADA CARACTERIZADA
          </Link>
        </li>
        <li>
          <Link to="armed-escort-menagement">
            <i className="fas fa-angle-right" />
            PRONTA RESPOSTA E RECUPERAÇÃO DE BENS
          </Link>
        </li>
        <li>
          <Link to="route-inspector">
            <i className="fas fa-angle-right" />
            AUDITOR E FISCAL DE ROTA
          </Link>
        </li>
        <li>
          <Link to="inteligence-regulation">
            <i className="fas fa-angle-right" />
            INTELIGÊNCIA
          </Link>
        </li>
        <li>
          <Link to="juridical-support">
            <i className="fas fa-angle-right" />
            APOIO JURÍDICO
          </Link>
        </li>
      </StyledBreadcrumb>
  
      <StyledLogistic
        id="logistic"
        style={{backgroundImage: `url(${Truck})`}}
      >
        <SectionContainer>
          <h2>
            <i className="fas fa-road" />
            Acompanhamento Logístico
          </h2>
          <div>
            <p>Recursos de controle e acompanhamento que agregam grande valor ao negócio através da logística, possibilitando a visualização da frota e produzindo indicadores para medição de resultados e otimizando os custos e recursos nas operações.</p>
            <p>Monitoramento e controle de veículos em trânsito ou pátios e seus indicadores.</p>
            <p>Com Monitor de Acompanhamento e Produtividade Logístico é possível se verificar de forma gráfica a movimentação de veículos, a porcentagem dos que estão em movimento e os que estão parado, tanto por veiculo e motoristas.</p>
            <p>Com as ferramentas de logística <b>SPARTACUS BRAZIL</b> tem-se a visibilidade completa em diversos segmentos: Transferências, Distribuições, Delivery, Entregas, Coletas, Faixas de Temperaturas, Violações e Alertas, Chegadas e Saídas, Tempos de Carga e Descarga e Telemetria de condução e outros</p>
          </div>
  
          <ul>
            <li>Visor de Frota, Agregado e Autônomo</li>
            <li>Integração de API</li>
            <li>Controle Sistêmico QR CODE</li>
            <li>Controle de Remessa</li>
            <li>Mapa de deslocamento</li>
            <li>Painéis B.I.</li>
            <li>Gestão de Jornada</li>
            <li>Painel de Dados</li>
            <li>Entrega Segura</li>
            <li>Visor Time Line</li>
            <li>Controle de Avaria</li>
            <li>APP de Registro de Entrega</li>
          </ul>
          <span className="clear" />
        </SectionContainer>
      </StyledLogistic>
  
      <StyledTracking
        id="tracking"
        className="format-style"
      >
        <SectionContainer className='padding'>
          <h2 className="left">
            <i className="fas fa-satellite" />
            Rastreamento
          </h2>
          <fieldset>
            <legend>LOCALIZADOR 100% SATELITAL</legend>
            <ol>
              <li><i className="fas fa-check-square" /> Produto Exclusivo com transmissão de posições via SATÉLITE de até 5 em 5 minutos.</li>
              <li><i className="fas fa-check-square" /> Posição através do GPS e transmissão via SATÉLITE em qualquer região do país (ate as áreas mais remotas) e fora do Brasil.</li>
            </ol>
            <ol className='grid'>
              <li><i className="fas fa-check-square" /> Roaming internacional Gratuito.</li>
              <li><i className="fas fa-check-square" /> NÃO DEPENDE da rede de telefonia celular.</li>
              <li><i className="fas fa-check-square" /> Comunica mesmo com Jammer ativo.</li>
              <li><i className="fas fa-check-square" /> Localizador GSM/SATELITAL </li>
              <li><i className="fas fa-check-square" /> Sistema Híbrido</li>
              <li><i className="fas fa-check-square" /> GSM Carga </li>
              <li><i className="fas fa-check-square" /> GSM Casco</li>
            </ol>
          </fieldset>
        </SectionContainer>

        <StyledImageDemo>
          <div className='satellite'>
            <div className='bg' />
            <div className='bg bg2' />
            <i className="fas fa-satellite-dish" />
          </div>

          <div className='integracao'>
            <p>Integração x Cliente</p>
            <p>Embarque de Itinerário</p>

            <i className="fas fa-chevron-down" />
            <i className="fas fa-chevron-left" />
            <div className='city'>
              <i className="fas fa-city" />
            </div>
          </div>

          <div className='central'>
            <i className="fas fa-long-arrow-alt-down" />
            <i className="fas fa-chevron-down one" />
            <i className="fas fa-chevron-down two" />
            <div className='icons'>
              <i className="fas fa-server one" />
              <i className="fas fa-desktop two" />
              <i className="fas fa-desktop bigger" />
              <i className="fas fa-desktop three" />
              <i className="fas fa-server four" />
              <i className="fas fa-user-alt user" />
            </div>
            <p>Data Centers STI / SPARTACUS BRAZIL</p>
            <p>Alocados no Canadá e Brasil</p>
            <i className="fas fa-chevron-up one" />
            <i className="fas fa-chevron-up two" />
            <div className='arrowDownCenter'>
            <i className="fas fa-chevron-down" />
            </div>
          </div>

          <div className='chart'>
            <div className='bg' />
            <i className="fas fa-user-tie" />
            <i className="fas fa-chart-pie" />
          </div>

          <div className='data-component'>
            <div className='city'>
              <i className="fas fa-building bone" />
              <i className="far fa-building" />
              <i className="fas fa-building btwo" />
            </div>

            <i className="fas fa-chevron-down" />
            <i className="fas fa-chevron-left" />

            <p>Envio de SMS e E-mail</p>
            <p>informado pelo Terminal de Dados</p>
          </div>
        </StyledImageDemo>

        <SectionContainer className='padding'>
          <fieldset>
            <legend>TELEMETRIA</legend>
            <p>Todas as informações e alertas da Telemetria podem ser transmitidas pelo LOCALIZADOR SATELITAL que possui:</p>
            <ol className='grid'>
              <li><i className="fas fa-check-square" /> Sensor Contador de Eventos, utilizado para medir acionamentos de Válvulas, Braço Hidráulico, Acionamento de Geradores e outros.</li>
              <li><i className="fas fa-check-square" /> Leitura de Horímetro (utilizado pelas máquinas Agrícolas)</li>
              <li><i className="fas fa-check-square" /> Sensor de movimento através do Acelerômetro.</li>
              <li><i className="fas fa-check-square" /> Sensor de Vibração.</li>
            </ol>
          </fieldset>
          <span className="clear" />
        </SectionContainer>
      </StyledTracking>

      <ArmedEscort id="armed-escort">
        <SectionContainer>
          <h2>
            <i className="fas fa-volume-off spinoff" />
            <i className="fas fa-car-side" />
            Escolta Armada Caracterizada
          </h2>
    
          <h4>
            <i className="fas fa-globe-americas" />
            Benefícios da “Rede Nacional de Escolta Armada”
          </h4>
          <div className='table'>
            {!isMobile && (
              <div className='th'>
                <div>Benefícios</div>
                <div>Contratante</div>
                <div>Escolta Armada</div>
              </div>
            )}
            {armedEscortList.map((row, index) => renderArmedEscortList(row, index))}
          </div>

          <div className='left-side'>
            <h3>Empresas devidamente homologadas e regularizadas nos órgãos pertinentes em todo território nacional.</h3>
            <ul>
              <li>Equipe de Agentes Treinados</li>
              <li>Agente Uniformizado</li>
              <li>Viatura Caracterizada</li>
              <li>Tecnologia de Rastreamento Embarcada</li>
              <li>Armamento Regular</li>
            </ul>
          </div>
          <iframe src="https://www.google.com/maps/d/embed?mid=1UHpCwZk8NatkO1meHrNNkwjvCWf1i_HU&ehbc=2E312F" />
        </SectionContainer>
  
      </ArmedEscort>

      <ArmedEscortManagement id="armed-escort-menagement">
        <SectionContainer>
          <h2 className="left">
            <i className="fas fa-user-shield" />
            Gestão de Escolta Armada
          </h2>
          <img src={Agent} alt="Logo Agente Segurança" />
          <p>O serviço de escolta armada é uma ferramenta preventiva e reativa empregada para a proteção de bens e conferência de situações divergêntes do programado que podem ser até suspeitas ou certezas de sinistros. O SGE é a solução para a gestão deste serviõs que facilita todo o ciclo de contratação, do acionamento ao pagamento, eliminando a burocracia de contratos, diminuindo custos e desperdícios decorrentes de erros operacionais.</p>

          <h4><i className="fas fa-asterisk" />Embarcador e Distribuidor</h4>
          <p>Otimize suas operações de forma integrada ao seu sistema interno, desde a separação de seus produtos ao embarque, automatizando todo o processos conjuntamente com as solicitações dos serviços de escolta atendendo as regras securitárias , reduzindo custos com mão de obra específica.</p>
          <h4><i className="fas fa-asterisk" />Transportador e Operador Logístico</h4>
          <p>Utilize uma base pré-cadastrada de prestadores em nível Nacional ou cadastre seus próprios prestadores e tenha toda a facilidade e controle sobre andamento de missões, financeiro e indicadores.</p>
          <h4><i className="fas fa-asterisk" />Empresas De Segurança</h4>
          <p>Tenha um canal comercial amplo e ágil para acionamento de missões e receba acionamentos de diversos transportadores e embarcadores, mesmo sem contrato direto com eles.</p>
          <h4><i className="fas fa-asterisk" />Gerenciadora De Risco E Centrais De Monitoramento</h4>
          <p>Visão completa dos serviços, diminuindo custos e tempo de controle nas atividades, fazendo uma gestão de excelência para seus clientes e parceiros.</p>
          <span className='clear' />
        </SectionContainer>
      </ArmedEscortManagement>

      <RouteInspector id="route-inspector">
        <SectionContainer>
          <h2>
            <i className="fas fa-user-ninja" />
            Pronta Resposta, Auditor ou Fiscal de Rota
          </h2>
          <iframe src="https://www.google.com/maps/d/embed?mid=1WjFkIoKALdWNuwzJDq4iqdYbPquO41_D&ehbc=2E312F" />
          <div className='right-side'>
            <p>Entende-se que o agente de campo PRONTA RESPOSTA, é o representante de ordem e preposto da Spartacus Brazil e também do cliente, neste contexto ele deve observar com segurança, inteligência e maestria, todo o envolto ao sinistro ou evento.</p>
            <p>O objetivo do profissional agente de Pronta Resposta é:</p>
            <div className='destak'>
              <p>Promover o atendimento imediato no local do sinistro ou evento a fins de assegurar o cumprimento de procedimentos, verificar desvio de <b>P.G.R.</b> (Plano Gerenciamento de Risco), situações suspeitas, prestar apoio ao motorista, veiculo, carga e recuperar Bens (Casco/Carga)</p>
              <p>Registrar com imagens o Local do evento, condições gerais:</p>
              <ul>
                <li>Do condutor (Motorista)</li>
                <li>Do veículo</li>
                <li>De todos os documentos ( condutor, veiculo e carga)</li>
                <li>Das condições gerais da carga</li>
                <li>Da condição geral da pista de rodagem</li>
                <li>Dos sensores e atuadores de rastreabilidade</li>
              </ul>
              <p>Sempre que necessário solicitar apoio de força publica local ( Guarda Municipal, Policia Militar, Policia Civil, Policia federal, Bombeiros, Defesa Civil ).</p>
              <p><b>Toda e qualquer ação do profissional agente de Pronta Resposta, deve simultaneamente ser informada e em comum acordo com a central da SPARTACUS BRAZIL.</b></p>
            </div>

            <p>O Auditor ou Fiscal de Rota é o profissional descaracterizado responsável pelo apoio e ou Acompanhamento ao transportes de cargas durante parte ou todo o trajeto, avaliando e coibindo eminentes riscos e fragilidades no percurso da rota.</p>
          </div>
        </SectionContainer>
      </RouteInspector>

      <InteligenceRegulation id="inteligence-regulation">
        <SectionContainer className='support'>
          <h2>
            <i className="fas fa-user-secret" />
            Inteligência, Sindicância ou Regulação
          </h2>
          <div className='principal'>EVENTO</div>
          <div className='first'>
            <h4>Inteligência</h4>
            <h6>Prevenção de Perdas</h6>
            <p>Produção de conhecimentos para planejar e executar ações para inibir perdas, criar instrumento para a instauração de procedimentos legais por parte das autoridades competentes e ou <i>compliance</i>.</p>
          </div>
          <div className='second'>
            <h4>Investigação</h4>
            <h6>Evitar Repetições</h6>
            <p>Produção de provas para entendimento do fato ocorrido, alimentar a Inteligência e desenvolver mecanismos para evitar repetição.</p>
            </div>
          <div className='third'>
          <h4>Sindicância</h4>
            <h6>Principais atividades</h6>
            <ul>
              <li>Entendimento de processos</li>
              <li>Pesquisas em fontes diversas (virtual e física)</li>
              <li>Análise de documentos</li>
              <li>Auditoria de procedimentos</li>
              <li>Monitoramento de pessoas,</li>
              <li>comunicações, documentos, câmeras, acessos e produtos</li>
              <li>Identificação de pessoas nocivas à empresa</li>
              <li>Manipulação de pessoas e informações</li>
              <li>Desinformação</li>
              <li>Desestabilização de grupo</li>
            </ul>
          </div>
          <span className='clear' />
        </SectionContainer>
      </InteligenceRegulation>

      <JuridicalSupport
        id="juridical-support"
        style={{backgroundImage: `url(${JuridalSupport})`}}
      >
        <SectionContainer>
          <h2>
            <i className="fas fa-balance-scale" />
            Apoio Jurídico Rota
          </h2>
          <p>Prestamos apoio jurídico à empresas que não têm departamento próprio ou que não querem empenhar sua estrutura orgânica nos processos.</p>
          <ul>
            <li>Criminal</li>
            <li>Trabalhista</li>
            <li>Civil</li>
            <li>Tributário</li>
            <li>Ambiental</li>
            <li>Comercial</li>
            <li>Digital</li>
            <li>Do Consumidor</li>
            <li>Contratual</li>
            <li>Securitário</li>
            <li>Penal Empresarial</li>
            <li>Compliance Corporativo</li>
          </ul>
        </SectionContainer>
      </JuridicalSupport>
      <Footer transparencyMenu={transparencyMenu} />
    </StyledContainer>
  )
}

Services.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  transparencyMenu: PropTypes.bool.isRequired,
}

export default Services
