import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Header from './components/Header'
import Home from './components/Home'
import WhoWeAre from './components/WhoWeAre'
import Services from './components/Services'
import Contact from './components/Contact'
import Politics from './components/Politics'

const INNER_WIDTH_PARAM = 760

const DefaultRoute = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= INNER_WIDTH_PARAM)
  const [transparencyMenu, setTransparencyMenu] = useState(false)

  useEffect(() => {
    window.addEventListener('scroll', () => {
      setTransparencyMenu(window.scrollY > 180)
    })
  }, [])

  useEffect(() => {
    window.addEventListener('resize', () => {
      const widthW = window.innerWidth

      if (widthW <= INNER_WIDTH_PARAM) {
        return setIsMobile(true)
      }

      return setIsMobile(false)
    })
  }, [])

  return (
    <Router>
      <Header
        isMobile={isMobile}
        transparencyMenu={transparencyMenu}
      />
      <Routes>
        <Route path="/" element={<Home
          transparencyMenu={transparencyMenu}
        />} />
        <Route path="/quem-somos" element={<WhoWeAre
          transparencyMenu={transparencyMenu}
        />} />
        <Route path="/servicos" element={<Services
          transparencyMenu={transparencyMenu}
          isMobile={isMobile}
        />} />
        <Route path="/contato" element={<Contact
          transparencyMenu={transparencyMenu}
        />} />
        <Route path="/politicas" element={<Politics
          transparencyMenu={transparencyMenu}
        />} />
      </Routes>
    </Router>
  )
}

export default DefaultRoute


