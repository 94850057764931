export const ContactType = [
  {
    value: 'Serviços',
    label: 'Serviços',
  }, {
    value: 'Administrativo',
    label: 'Administrativo',
  }, {
    value: 'Portal',
    label: 'Portal',
  }
]