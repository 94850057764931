import React from 'react'
import PropTypes from 'prop-types'
import { StyledContainer } from './styles'
import Logomarca from '@/imgs/logomarca-branco.png'

const HeaderArticle = (props) => {
  const {
    children
  } = props

  return (
    <StyledContainer>
      <div>
        <h2>
          {children}
        </h2>
      </div>
      <img src={Logomarca} alt="logomarca spartacus brazil" />
    </StyledContainer>
  )
}

HeaderArticle.propTypes = {
  children: PropTypes.any.isRequired,
}

export default HeaderArticle
