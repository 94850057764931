import styled from '@emotion/styled'

export const SectionContainer = styled.section`
  max-width: 1280px;
  margin: 0 auto;
`

export const ContactContainer = styled.section`
  padding: 30px 20px 100px;

  p {
    margin-bottom: 10px;
    line-height: 18px;
  }

  h2 {
    padding-bottom: 10px;
    margin: 40px 0 20px;
    border-bottom: 3px solid var(--color-primary);

    i {
      color: var(--color-primary);
      margin-right:  7px;
    }

    span {
      display: block;
      font-size: 16px;
    }
  }

  ul {
    list-style: disc;
    padding-left: 20px;
    margin: 0 0 20px;

    li {
      font-size: 18px;
      line-height: 23px;
    }
  }

  @media (min-width: 1280px) {
    p {
     font-size: 18px;
      line-height: 23px;
    }
  }
`
