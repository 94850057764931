import styled from '@emotion/styled'

export const StyledFooter = styled.footer`
  background-color: var(--color-black-1);
  border-top: 3px solid var(--color-primary);
  padding: 20px;
  color: var(--color-secondary);
  text-align: center;

  p {
    display: inline-block;
    width: 100%;
    margin-bottom: 20px;
  }

  a {
    display: inline-block;
    width: 100%;
    font-size: 14px;
    color: var(--color-gray-dark);

    span {
      display: inline-block;
      margin-right: 10px;
    }

    img {
      width: 100px;
    }
  }

  @media (min-width: 760px) {
    text-align: left;

    p {
      width: calc(100% - 250px);
      margin: 0 20px 0 0;
    }

    a {
      width: 230px;
      text-align: right;
    }
  }
`

export const StyledWhats = styled.div`
  position: fixed;
  bottom: -100%;
  right: 20px;
  overflow: hidden;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  padding: 10px;
  background-color: #00e676;
  transition: all .2s ease-in-out;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, .4);

  &.show { 
    bottom: 60px;
  }

  a {
    height: 40px;
    display: block;
    width: 40px;
    vertical-align: middle;

    i {
      font-size: 40px;
      color: var(--color-light);
      line-height: 40px;
    }
    
    span {
      display: inline-block;
      overflow: hidden;
      width: 0;
      height: 0;
      color: var(--color-light);
      font-size: 18px;
      transition: all .2s ease-in-out;
    }
  }

  &:hover {
    width: 200px;
    border-radius: 50px;

    a {
      width: 100%;

      i {
        width: 40px;
      }

      span {
        width: calc(100% - 50px);
        height: auto;
      }
    }
  }

  @media (min-width: 760px) {
    &.show { 
      bottom: 40px;
    }
  }
`