import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import LOGO from '@/imgs/logo-spartacus-home.png'
import { clientList } from '@/TextsPagesMock/clientsList'
import { serviceList } from '@/TextsPagesMock/servicesList'
import { Link } from "react-router-dom"
import LogoBranco from '@/imgs/logomarca-branco.png'
import Footer from '@/components/Footer'
import {
  StyledContainer,
  StyledLogo,
  StyledBannerWhoWeAre,
  ServiceSection,
  StyledBoxes,
  ClientSection,
  ContainerBoxes,
} from './styles'

const URL = '/logos/'

const MSG_SHOW = [
  {
    title: 'Inteligência e Investigação',
    sub: 'Investigação de Sinistros e Fraudes'
  },
  {
    title: 'Serviço de Segurança',
    sub: 'Soluções Completas e Eficazes'
  }
]

const Home = (props) => {
  const { transparencyMenu } = props
  const [currentLogoIndex, setCurrentLogoIndex] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      const index = MSG_SHOW.length - 1

      setCurrentLogoIndex(currentLogoIndex => currentLogoIndex === index ? 0 : currentLogoIndex + 1)
    }, 7000)
    return () => clearInterval(interval)
  }, []);

  const renderBox = (box, i) => (
    <div className={`boxes ${box.colorType}`} key={i}>
      <h4>{box.title}</h4>
      <div className="img">
        <img src={`${URL}${box.imgUrl}`} alt={box.imgUrl} />
      </div>
    </div>
  )

  const renderService = (box, i) => {
    const renderIcon = () => {
      if (box.renderIcon) return (
        <>
          <i className={box.icon[0]} />
          <i className={box.icon[1]} />
        </>
      )

      return (
        <i className={box.icon} />
      )
    }

    return (
      <StyledBoxes key={i}>
        <div>
          {renderIcon()}
        </div>
        {box.title}
        <p>
          {box.text}
        </p>
        <a href={box.url} className="button"><i className="fas fa-plus" /></a>
      </StyledBoxes>
    )
  }

  const renderCaseList = (item, index) => (
    <div className={`group group-${index}`}>
      <h3>{item.product}</h3>
      <ContainerBoxes className={`items-${index}`}>
        {item.list.map((box, index) => renderBox(box, index))}
      </ContainerBoxes>
    </div>
  )

  return (
    <>
      <StyledContainer className='has-header'>
        <StyledLogo>
          <div>
            <img src={LOGO} alt="Logo Spartacus Brazil"  />
            <h2>{MSG_SHOW[currentLogoIndex].title}</h2>
            <h3>{MSG_SHOW[currentLogoIndex].sub}</h3>
          </div>
        </StyledLogo>

        <StyledBannerWhoWeAre>
          <h3>ESPECIALIZADA NOS SERVIÇOS DE GESTÃO EM SEGURANÇA LOGÍSTICA.</h3>
          <Link to="/quem-somos" className='button'>Saber Mais</Link>
        </StyledBannerWhoWeAre>

        <ServiceSection>
          <h2>
            Serviços Ativos
            <img src={LogoBranco} alt="logo spartacus" />
          </h2>
          <div className="containerBoxes">
            {serviceList.map((box, index) => renderService(box, index))}
          </div>
        </ServiceSection>

        <ClientSection>
          <h2>
            Alguns de Nossos Cases
            <img src={LogoBranco} alt="logo spartacus" />
          </h2>

          <div className='content'>
            <div className='container-boxes'>
              {clientList.map((item, index) => renderCaseList(item, index))}
            </div>
          </div>
        </ClientSection>
        <Footer transparencyMenu={transparencyMenu} />
      </StyledContainer>
    </>
  )
}

Home.propTypes = {
  transparencyMenu: PropTypes.bool.isRequired,
}

export default Home
