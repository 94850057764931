import styled from '@emotion/styled'

export const StyledContainer = styled.section`
  h2 {
    font-size: 27px;
    text-transform: uppercase;
    font-weight: 600;
    text-align: center;
    margin-bottom: 30px;
    border-bottom: 2px solid var(--color-primary);
    padding-bottom: 10px;
    position: relative;

    i {
      margin-right: 5px;
      color: var(--color-primary);

      &.spinoff {
        font-size: 9px;
        position: absolute;
        transform: rotate(90deg);
        top: -4px;
        margin-left: 13px;
      }
    }
  }

  p {
    margin-bottom: 10px;
    line-height: 18px;
    letter-spacing: .6px;
  }

  @media (min-width: 760px) {
    h2 {
      text-align: left;
      padding: 0 20px 10px;

      &.left {
        text-align: right;
      }

      i {
        margin-right: 10px;
      }
    }

    p {
      font-size: 18px;
      line-height: 22px;
    }
  }
`

export const StyledBreadcrumb = styled.ul`
  width: 100%;
  background-color: var(--color-primary);
  padding: 20px;

  li {
    margin-top: 10px;
    border-top: 1px solid;
    padding-top: 10px;

    &:first-of-type {
      margin-top: 0;
      border-top: none;
    }

    a {
      color: var(--color-black-3);
      cursor: pointer;

      i {
        margin-right: 5px;
      }
    }
  }

  @media (min-width: 760px) {
    text-align: center;

    li {
      width: auto;
      display: inline-block;
      border-top: none;
      margin: 0;
      padding: 0;

      a {
        padding: 10px;
        display: block;
      }
    }
  }
`

export const SectionContainer = styled.section`
  max-width: 1280px;
  margin: 0 auto;
`

export const StyledLogistic = styled.div`
  border-bottom: 5px solid var(--color-primary);
  background-size: auto 150px;
  background-repeat: no-repeat;
  background-position: bottom left;
  padding: 30px 15px 160px;
  margin-bottom: 50px;

  ul {
    margin: 20px 0;

    li {
      padding: 8px 10px;
      border-bottom: 1px solid var(--color-gray-light);
  
      &:last-of-type {
        border-bottom: none;
      }
    }
  }

  @media (min-width: 760px) {
    padding-top: 50px;

    & > div {
      width: calc(100% - 320px);
      display: inline-block;
      margin-right: 20px;
      vertical-align: top;
      margin-top: 15px;
    }
    
    ul {
      display: inline-block;
      margin: 30px 0 0;
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, calc(50% - 5px));
      grid-gap: 0 10px;
    }
  }

  @media (min-width: 1280px) {
    background-size: auto 385px;

    & > div {
      width: 500px;
      display: block;
      margin: 0 0 0 calc(100% - 500px);
    }

    ul {
      width: 500px;
      float: right;
      display: grid;
      grid-template-columns: repeat(2, calc(50% - 10px));
      grid-gap: 0 20px;
      text-align: left;

      li {
        &:last-of-type {
          border-bottom: 1px solid var(--color-gray-light);
        }
      }
    }
  }

  @media (min-width: 1680px) {
    & > div {
      width: 763px;
      margin: 0 0 0 calc(100% - 763px);
    }

    ul {
      width:763px;
    }
  }
`

export const ArmedEscort = styled.article`
  border-bottom: 5px solid var(--color-primary);
  padding: 30px 15px 0;
  margin-bottom: 50px;

  h4 {
    color: var(--color-primary);
    font-size: 18px;
    margin-bottom: 20px;
    text-align: center;

    i {
      color: var(--color-black-3);
      margin-right: 10px;
    }
  }

  .table {
    width: 100%;
    margin-bottom: 30px;
    background-color: var(--color-gray-light);
    border: 1px solid var(--color-gray-light);

    .tr {
      padding: 10px 10px 0;
      display: grid;
      grid-template-columns: calc(100% - 2px);
      grid-gap: 1px;

      &:nth-of-type(even) {
        background-color: var(--color-light);
      }

      & > div {
        margin-bottom: 15px;
        font-size: 18px;

        span {
          display: block;
          font-weight: 700;
          margin-bottom: 2px;
          text-transform: uppercase;
          font-size: 12px;
        }
      }
    }

    @media (min-width: 760px) {
      .th {
        display: grid;
        grid-template-columns: repeat(3, calc(33.333333% - .5px));
        grid-gap: 1px;
        border-bottom: 1px solid var(--color-gray-light);
  
        div {
          padding: 0 10px;
          line-height: 35px;
          background-color: var(--color-light);
          font-weight: 700;
          text-transform: uppercase;
          font-size: 14px;
        }
      }
      
      .tr {
        display: grid;
        grid-template-columns: repeat(3, calc(33.333333% - .5px));
        grid-gap: 1px;
        border-bottom: 1px solid var(--color-gray-light);
        padding: 0;

        &:last-of-type {
          border-bottom: none;
        }
        
        & > div {
          background-color: var(--color-light);
          padding: 15px 10px;
          margin: 0;
        }

        &:nth-of-type(even) {
          background-color: var(--color-gray-light);
        }

        &:hover {
          & > div {
            background-color: var(--color-warning);
          }
        }
      }
    }
  }
  
  ul {
    list-style: disc;
    padding-left: 20px;
    margin: 20px 0;

    li {
      font-size: 18px;
      line-height: 23px;
    }
  }

  iframe {
    width: 100%;
    height: 500px;
  }

  @media (min-width: 760px) {
    padding-top: 50px;

    .left-side {
      width: 400px;
      display: inline-block;
    }

    iframe {
      width: calc(100% - 420px);
      margin-left: 20px;
      display: inline-block;
      vertical-align: top;
    }
  }

  @media (min-width: 1280px) {
    .left-side {
      width: 600px;
    }

    iframe {
      width: calc(100% - 620px);
    }
  }
`

export const ArmedEscortManagement = styled.article`
  border-bottom: 5px solid var(--color-primary);
  padding: 30px 15px;
  margin-bottom: 50px;

  img {
    width: 100%;
    margin-bottom: 20px;
    box-shadow: 20px 0 0 0 var(--color-light);
  }

  h4 {
    color: var(--color-primary);
    margin: 20px 0 10px;
    border-bottom: 1px solid var(--color-black-3);
    padding-bottom: 10px;
    text-transform: uppercase;

    i {
      margin-right: 10px;
      color: var(--color-black-3);
    }
  }

  @media (min-width: 760px) {
    padding-top: 50px;

    img {
      width: 450px;
      vertical-align: top;
      margin-right: 20px;
      float: left;
    }

    p {
      display: inline;
    }
  }
`

export const RouteInspector = styled.article`
  border-bottom: 5px solid var(--color-primary);
  padding: 30px 15px;
  margin-bottom: 50px;

  iframe {
    width: 100%;
    height: 500px;
    margin-bottom: 30px;
  }

  .destak {
    background-color: var(--color-gray-ultra-light);
    border: 1px solid var(--color-gray-dark);
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
  
    ul {
      list-style: disc;
      padding-left: 20px;
      margin: 20px 0;
  
      li {
        font-size: 18px;
        line-height: 23px;
      }
    }
  }

  @media (min-width: 760px) {
    padding-top: 50px;

    .right-side {
      width: 400px;
      display: inline-block;
    }

    iframe {
      width: calc(100% - 420px);
      margin-right: 20px;
      display: inline-block;
      vertical-align: top;
    }
  }

  @media (min-width: 1280px) {
    .right-side {
      width: 600px;
    }

    iframe {
      width: calc(100% - 620px);
    }
  }
`

export const InteligenceRegulation = styled.article`
  border-bottom: 5px solid var(--color-primary);
  padding: 30px 15px;
  margin-bottom: 50px;
  position: relative;

  .principal {
    width: 100%;
    background-color: var(--color-black-3);
    color: var(--color-primary);
    padding: 20px 10px;
    text-align: center;
    font-size: 26px;
    font-weight: 600;
    border-radius: 5px;
    border: 3px solid var(--color-primary);
    margin-bottom: 30px;
  }

  h4 {
    background-color: var(--color-primary);
    color: var(--color-black-3);
    border: 3px solid var(--color-black-3);
    padding: 15px 10px;
    text-align: center;
    font-size: 22px;
    text-transform: uppercase;
    border-radius: 5px;
    margin-bottom: 10px;
  }

  h6 {
    margin-bottom: 10px;
    font-size: 19px;
  }

  ul {
    list-style: disc;
    padding-left: 20px;
    margin: 20px 0;

    li {
      font-size: 18px;
      line-height: 23px;
    }
  }

  .first, .second, .third {
    width: 250px;
    position: relative;

    &::before {
      content: '';
      display: block;
      position: absolute;
      background-color: var(--color-primary);
      bottom: 100%;
      width: 3px;
    }
  }

  .first {
    margin: 0 auto 30px;

    &::before {
      height: 32px;
      left: calc(50% - 1.5px);
    }
  }

  .second {
    margin: 0 40px 30px 0;

    &::before {
      height: 267px;
      left: 10px;
    }
  }
  
  .third {
    margin: 0 0 30px calc(100% - 250px);
    
    &::before {
      height: 466px;
      right: 10px;
    }
  }

  @media (min-width: 760px) {
    padding-top: 50px;

    .support {
      position: relative;

      &::before {
        content: '';
        display: block;
        position: absolute;
        background-color: var(--color-primary);
        right: 170px;
        top: 103px;
        height: 3px;
        width: calc(100% - 340px);
      }
    }

    .first, .second, .third {
      width: 340px;
      vertical-align: top;
    }

    .second {
      margin: 0;
      display: inline-block;

      &::before {
        height: 305px;
        left: 170px;
      }
    }

    .third {
      margin: 0;
      float: right;
      display: inline-block;

      &::before {
        height: 305px;
        right: 170px;
      }
    }

    .principal {
      width: 250px;
      margin: 0 auto 30px;
      position: relative;
    }
  }

  @media (min-width: 1280px) {
    min-height: 550px;
    .first, .second, .third {
      position: absolute;
    }

    .first {
      left: calc(50% - 170px);
      top: 240px;

      &::before {
        height: 101px;
      }
    }

    .second {
      left: 20px;
      top: 75px;

      &::before {
        display: none;
      }
    }

    .third {
      right: 20px;
      top: 75px;

      &::before {
        display: none;
      }
    }
  }
`

export const JuridicalSupport = styled.article`
  border-bottom: 5px solid var(--color-primary);
  padding: 30px 15px 185px;
  margin-bottom: 50px;
  background-position: bottom right;
  background-size: 400px;
  background-repeat: no-repeat;

  ul {
    list-style: disc;
    padding-left: 20px;
    margin: 20px 0;

    li {
      font-size: 18px;
      line-height: 23px;
    }
  }

  @media (min-width: 760px) {
    padding-top: 50px;
    background-size: calc(100% - 122px);
  }

  @media (min-width: 1280px) {
    background-size: 1122px;
  }
`

export const StyledTracking = styled.article`
  padding: 30px 0;

  .padding {
    padding: 0 15px ;
  }

  fieldset {
    legend {
      background-color: var(--color-black-7);
      padding: 10px 20px;
      color: var(--color-primary);
    }

    ol {
      margin: 0;

      li {
        list-style: none;
        margin-bottom: 5px;

        i{
          box-shadow: 0 0 0 10px #fecc00 inset;
        }
      }
    }
  }

  @media (min-width: 760px) {
    fieldset {
      ol {
        &.grid {
          display: grid;
          grid-template-columns: repeat(3, 33.33333%);
          grid-gap: 0;

          li {
            padding-right: 15px;
          }
        }

        li {
          font-size: 18px;
          line-height: 22px;
          margin-bottom: 10px;
        }
      }
    }
  }
`

export const StyledImageDemo = styled.div`
  position: relative;
  height: 550px;
  max-width: 1000px;
  margin: 0 auto 30px;
  background: rgb(254,204,0);
  background: radial-gradient(circle, rgba(254,204,0,0.23012955182072825) 24%, rgba(2,0,36,0) 32%);

  @keyframes Satellite {
    from {
      left: 26px;
      transform: rotate(98deg);
    }
    to {
      left: 90px;
      transform: rotate(171deg)
    }
  }

  @keyframes SatelliteBG {
    from {
      left: 41px;
      top: 10px;
    }
    to {
      left: 30px;
      top: 0;
    }
  }

  @keyframes SatelliteBG2 {
    from {
      top: 83px;
      left: -10px;
    }
    to {
      top: 73px;
      left: 0px;
    }
  }

  @keyframes Chart {
    from {transform: scale(1);}
    to {transform: scale(1.6);)}
  }

  .satellite {
    position: relative;
    width: 200px;
    height: 200px;

    .bg {
      width: 150px;
      height: 150px;
      opacity: .06;
      background-color: var(--color-black-9);
      border-radius: 50%;
      position: absolute;
      z-index: 1;
      animation: SatelliteBG ease-in-out 5s alternate infinite;

      &.bg2 {
        width: 100px;
        height: 100px;
        background-color: var(--color-primary);
        z-index: 2;
        opacity: .2;
        animation: SatelliteBG2 ease-in-out 5s alternate infinite;
      }
    }

    i.fa-satellite-dish {
      font-size: 100px;
      color: var(--color-primary);
      position: absolute;
      top: 0;
      z-index: 3;
      animation: Satellite ease-in-out 5s alternate infinite;
    }
  }

  .integracao, .data-component {
    position: absolute;
    top: 0;
    right: 15px;
    width: calc(100% - 200px);
    padding: 10px;
    height: 160px;
    border-top: 2px dotted;
    text-align: right;
    border-right: 2px dotted;

    p {
      margin: 0 0 1px;
      font-size: 14px;
      font-weight: 700;
      position: relative;
      z-index: 10;
    }

    .city {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      background-color: var(--color-warning);
      text-align: center;
      position: absolute;
      line-height: 99px;
      right: 30px;
      top: 55px;

      i {
        color: var(--color-black-5);
        font-size: 44px;
      }
    }

    & > i {
      &.fas {
        font-size: 20px;
        color: var(--color-primary);
        position: absolute;
      }

      &.fa-chevron-left {
        top: -10px;
        left: -10px;
      }

      &.fa-chevron-down {
        bottom: -10px;
        right: -10px;
      }
    }
  }

  .central {
    position: absolute;
    top: 183px;
    width: calc(100% - 28px);
    left: 14px;
    padding: 40px 0;

    &:before, &:after {
      width: 100%;
      content: '';
      display: block;
      position: absolute;
      background-color: var(--color-primary);
      height: 3px;
    }

    &:before {
      top: 0;
      right: 0;
    }
    
    &:after {
      left: 0;
      bottom: 0;
    }

    i {
      color: var(--color-primary);
      font-size: 25px;
      position: absolute;

      &.fa-long-arrow-alt-down {
        top: 1px;
        left: calc(50% - 5px);
      }

      &.fa-chevron-down {
        top: -14px;
        font-size: 23px;

        &.one {
          left: -5px;
          transform: rotate(45deg);
        }
        
        &.two {
          right: -5px;
          transform: rotate(-45deg);
        }
      }

      &.fa-chevron-up {
        bottom: -14px;
        font-size: 23px;

        &.one {
          left: -5px;
          transform: rotate(-45deg);
        }
        
        &.two {
          right: -5px;
          transform: rotate(45deg);
        }
      }
    }

    .arrowDownCenter {
      position: absolute;
      height: 38px;
      width: 23px;
      bottom: 0;
      left: calc(50% - 11.5px);

      i {
        top: 0;
      }

      &:after {
        content: '';
        display: block;
        position: absolute;
        width: 3px;
        height: 15px;
        background-color: var(--color-primary);
        left: calc(50% - 2.5px);
        bottom: 0;
      }
    }


    .icons {
      width: 270px;
      height: 80px;
      margin: 0 auto;
      position: relative;
      
      i {
        font-size: 50px;
        position: absolute;
        top: 7px;
        color: var(--color-black-8);

        &.bigger {
          font-size: 60px;
          left: 100px;
          top: 0;
        }

        &.one {
          left: 0;
        }

        &.two {
          left: 51px;
        }

        &.three {
          left: 161px;
        }

        &.four {
          left: 218px;
        }

        &.user {
          color: var(--color-primary);
          font-size: 55px;
          left: 107px;
          top: 19px;
        }
      }
    }

    p {
      font-weight: 800;
      margin: 0;
      text-align: center;
    }
  }

  .data-component {
    top: auto;
    right: auto;
    left: 15px;
    bottom: 0;
    border: none;
    text-align: left;
    border-bottom: 2px dotted;
    border-left: 2px dotted;
    width: calc(100% - 187px);
    padding-top: 83px;
    height: 130px;

    i {
      &.fas {
        font-size: 20px;
        color: var(--color-primary);
        position: absolute;
      }

      &.fa-chevron-down {
        top: -19px;
        left: -10px;
        bottom: auto;
        right: auto;
      }
      
      &.fa-chevron-left {
        top: auto;
        left: auto;
        bottom: -11px;
        right: -17px;
      }
    }

    .city {
      left: 34px;
      top: 0px;

      i {
        &.fas {
          font-size: 38px;
          position: absolute;
          bottom: 19px;
        }

        &.bone {
          left: -5px;
        }
      }
    }
  }

  .chart {
    position: absolute;
    width: 170px;
    height: 90px;
    bottom: -10px;
    right: 15px;

    i {
      font-size: 80px;
      position: absolute;
      right: 0;

      &.fa-chart-pie {
        color: var(--color-primary);
        top: -66px;
        font-size: 60px;
        right: 20px;
        animation: Chart ease-in-out 3s alternate infinite;
      }

      &.fa-user-tie {
        right: 66px;
      }
    }

    .bg {
      width: 100px;
      height: 100px;
      opacity: .1;
      background-color: var(--color-black-9);
      border-radius: 50%;
      position: absolute;
      z-index: 1;
      top: -18px;
      right: 52px;
    }
  }

  @media (min-width: 760px) {
    height: 610px;
    margin-bottom: 50px;

    .satellite {
      position: absolute;
      top: 10px;
      left: 50px;
    }

    .integracao {
      top: 36px;
      width: calc(100% - 330px);
      right: 70px;
      height: 120px;
      padding-right: 70px;

      .city {
        top: -10px;
        right: -25px;
      }

      p {
        font-size: 22px;
      }
    }

    .central {
      width: calc(100% - 140px);
      left: 70px;

      .icons {
        height: 140px;
        width: 534px;

        i {
          font-size: 100px;

          &.bigger {
            font-size: 120px;
            left: 201px;
          }

          &.two {
            left: 101px;
          }

          &.three {
            left: 321px;
          }

          &.four {
            left: 434px;
          }

          &.user {
            left: 212px;
            top: 19px;
            font-size: 113px;
          }
        }
      }

      p {
        font-size: 25px;
      }
    }

    .chart {
      right: 70px;
      bottom: -10px;
    }

    .data-component {
      bottom: 30px;
      left: 70px;
      width: calc(100% - 310px);
      padding-left: 60px;
      padding-top: 44px;
      height: 100px;

      .city {
        top: auto;
        bottom: -26px;
        left: -40px;
      }

      p {
        font-size: 22px;
      }
    }
  }
`