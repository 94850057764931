import React from 'react'
import PropTypes from 'prop-types'
import HeaderArticle from '@/components/fragments/HeaderArticle'
import CardArticle from '../fragments/CardArticle'
import Footer from '@/components/Footer'
import { StyledContainer, StyledArticle } from './styles'

const WhoWeAre = (props) => {
  const { transparencyMenu } = props

  return (
    <StyledContainer className='has-header'>
      <HeaderArticle>
        <i className="far fa-building" />
        Sobre a <span>Spartacus</span> Brazil
        <span className='subTittle'>Especializada nos serviços de GESTÃO EM SEGURANÇA LOGÍSTICA.</span>
      </HeaderArticle>
      <StyledArticle>
        <CardArticle title="Nossos Objetivos">
          <p>As ações da Spartacus Brazil objetiva mitigar Riscos e Sinistralidades.</p>
          <p>A <b>Spartacus Brazil</b> especializada em Segurança Privada identificou no mercado de logística inúmeras dificuldades, e sua principal meta é buscar incansavelmente o aprimoramento no entendimento para o atendimento dos anseios de seus clientes e parceiros em suas operações.</p>
          <p>Acreditamos que um dos principais fatores negativo nas operações logísticas é a insegurança, onde por falta de processos especializados de gestão e muitas vezes o não cumprimento das regras securitárias se promove e ou facilita um alto índice de sinistralidade.</p>
          <p>Neste sentido a <b>Spartacus Brazil</b> vem propor apoio consultivo em todo o processo logístico nível Brasil, com elaboração de Planos Operacionais; gestão de processos, de pessoas e tecnologia, aplicando software específicos para as áreas envolvidas, desenvolvimento de PGR&apos;s ( Plano de Gerenciamento de Riscos ) para as operações de transportes de carga, corretoras de seguros e embarcadores, além de treinamentos aos envolvidos para aprimoramentos técnicos operacionais.</p>
        </CardArticle>
  
        <CardArticle title="Nossos Valores">
          <h4>Valores que preservamos e praticamos no Mercado Logístico</h4>
          <h3><i className="fas fa-rocket" />MISSÃO:</h3>
          <p>Fornecer soluções e serviços com segurança e excelência, por meio de pessoas qualificadas, customizando os custos de cada operação, agregando valor aos clientes e parceiros, visando a sustentabilidade do negócio.</p>
  
          <h3><i className="fas fa-eye" />VISÃO:</h3>
          <p>Ser a empresa líder em seu segmento, reconhecida por sua excelência, segurança, inovação e criação de valores.</p>
  
          <h3><i className="fas fa-seedling" />VALORES:</h3>
          <p><b>Ética:</b> Norteia nossas ações e decisões.</p>
          <p><b>Resultados:</b> Nosso compromisso.</p>
          <p><b>Relacionamento duradouro:</b> São valorizados e estimulados.</p>
          <p><b>Respeito:</b> Fortalece nossas relações.</p>
          <p><b>Segurança:</b> Sempre em primeiro lugar.</p>
          <p><b>Superação:</b> É o que nos move.</p>
  
          <h3><i className="fas fa-plus" />E MAIS:</h3>
          <ul>
            <li><b>Comportamento ético -</b> Agir com integridade e transparência, priorizando a honestidade nas relações pessoais e profissionais.</li>
            <li><b>Comprometimento -</b> Desenvolver alto grau de compromisso com os resultados, de forma a tomar todas as providências necessárias para atingir o objetivo de uma missão.</li>
            <li><b>Flexibilidade -</b> Relacionamento com diferentes públicos, desenvolver diversas atividades, mudar estratégias e procurar novas possibilidades para atingir seus objetivos.</li>
            <li><b>Foco em resultados -</b> Conduzir os processos, praticando uma melhor gestão, atingindo os resultados esperados.</li>
            <li><b>Inovação -</b> Percebermos as necessidades do mercado, buscando novas ideias e estratégias que agregam valor aos resultados e garantam a competitividade da empresa no mercado.</li>
            <li><b>Negociação -</b> Promover melhores projetos, expondo e ouvindo novas ideias, argumentar, persuadir e chegar as conclusões produtivas.</li>
            <li><b>Visão estratégica -</b> Analisar uma situação e criar ou aperfeiçoar estratégias para potencializar os resultados.</li>
            <li><b>Relacionamento interpessoal -</b> Relacionamento de forma positiva e produtiva com diferentes tipos de públicos na convivência diária em busca de resultados comuns.</li>
          </ul>
        </CardArticle>
      </StyledArticle>
  
      <Footer transparencyMenu={transparencyMenu} />
    </StyledContainer>
  )
}

WhoWeAre.propTypes = {
  transparencyMenu: PropTypes.bool.isRequired,
}

export default WhoWeAre
