import React from 'react'
import PropTypes from 'prop-types'
import LogoIvan from '@/imgs/logo-horion.png'
import { StyledFooter, StyledWhats } from './styles'

const Footer = (props) => {
  const { transparencyMenu } = props
  return (
    <StyledFooter>
      <p>Todos os direitos reservados © - Spartacus Brazil</p>
      <a
        href="http://ivanoliveira.com.br"
        rel="noreferrer"
        target="_blank"
      >
        <span>Desenvolvido por </span>
        <img src={LogoIvan} alt="Logo Ivan Oliveira" />
      </a>
  
      <StyledWhats className={transparencyMenu ? 'show' : ''}>
        <a
          href="https://wa.me/5511989414044"
          rel="noreferrer"
          target="_blank"
        >
          <i className="fa-brands fa-whatsapp" />
          <span>Fale agora com a Spartacus Brazil</span>
        </a>
      </StyledWhats>
    </StyledFooter>
  )
}

Footer.propTypes = {
  transparencyMenu: PropTypes.bool.isRequired,
}

export default Footer
